/* eslint-disable import/prefer-default-export */
import { QueryFunctionContext } from '@tanstack/react-query';
import getAxiosInstance from '../axiosClient';
import type { iAnalyticsDataList, } from '../../interfaces/sampleInterface';
// @flow

const requestCustomization = getAxiosInstance(process.env.REACT_APP_CHOICE_2_CUSTOMIZATION_API_URL);

export async function getThemeCustomizationRequest(context: QueryFunctionContext) {
    const [, queryPayload] = context.queryKey;
    const themeId  = queryPayload;
    const url = `/text-customization-request/${themeId}`;
    return await requestCustomization.get(url);
}