import React, { useState, useEffect, useRef } from 'react';
import { Form, Input, Button, Modal } from 'antd';
import fileImage from '../../../../assets/images/file.svg';
import { useTextCustomizationRequest } from '../../../../data/hooks/utils';
import { createTextCustomizationRequest } from '../../../../store/requests/themes';

function hasFifteenDaysPassed(dataObject) {
  if (!dataObject || typeof dataObject.createdOn !== 'number') {
    console.error("Invalid input: Function expects an object with a numeric 'createdOn' field.");
    return false;
  }
  const createdOnSeconds = dataObject.createdOn;
  const createdOnMilliseconds = createdOnSeconds * 1000;
  const currentMilliseconds = Date.now(); // Equivalent to new Date().getTime()
  const differenceInMilliseconds = currentMilliseconds - createdOnMilliseconds;
  const fifteenDaysInMilliseconds = 15 * 24 * 60 * 60 * 1000;
  return differenceInMilliseconds >= fifteenDaysInMilliseconds;
}

const TextAreaEditor = ({ privacyBodyChange, lang, content, setContents, onChange, fixedSegments, themeId }) => {
  const [form] = Form.useForm();

  const { pathname } = window.location;
  const isEdit = pathname.includes('/edit');
  
  const [reviewRequestModal, setReviewRequestModal] = useState(false);
  const [successRequestModal, setSuccessRequestModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referenceId, setReferenceId] = useState(null);
  const [reviewText, setReviewText] = useState({
    en: null,
    fr: null,
    bg: null
  });
  
  const textareaRef = useRef(null);
  const previousContentRef = useRef(content);

  const { data: textCustomizationData, isLoading: textCustomizationDataLoading, refetch } = useTextCustomizationRequest(themeId);

  // Sync textarea with content prop
  useEffect(() => {
    if (textareaRef.current && textareaRef.current.value !== content) {
      textareaRef.current.value = content;
    }
    previousContentRef.current = content;
  }, [content]);

  useEffect(() => {
    privacyBodyChange(content, lang);
  }, [content]);

  useEffect(() => {
    if (textCustomizationData && textCustomizationData.length > 0) {
      const data = textCustomizationData.find(item => item.language === lang && item.customizationType === 'advanced');
      if (data) {
        setReviewText((prev) => ({
          ...prev,
          [lang]: data.customizationText,
        }));
      }
    }
  }, [textCustomizationData]);

  // Check if the cursor is inside a fixed segment
  const isCursorInsideFixedSegment = position => {
    for (let segment of fixedSegments) {
      const segmentStart = content.indexOf(`<${segment}>`);
      const segmentEnd = segmentStart + segment.length + 2;
      if (position > segmentStart && position < segmentEnd) {
        console.log('Cursor inside fixed segment');
        setReviewRequestModal(true);
        return true;
      }
    }
    return false;
  };

  // Handle content change
  const handleChange = e => {
    const newValue = e.target.value;

    // Extract fixed segments from new content
    const regex = /<([^>]+)>/g;
    const fixedMatches = newValue.match(regex) || [];

    // Validate that fixed segments remain unchanged
    const isValid = fixedMatches.every((match, index) => {
      const cleanMatch = match.replace(/[<>]/g, '').trim();
      return cleanMatch === fixedSegments[index];
    });

    if (isValid && fixedMatches.length === fixedSegments.length) {
      onChange(newValue);
      previousContentRef.current = newValue;
    } else {
      onChange(previousContentRef.current);
    }
  };

  // Handle keydown to prevent deleting fixed segments
  const handleKeyDown = e => {
    const textarea = textareaRef.current;
    const position = textarea.selectionStart;

    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
      return;
    }

    if (e.key === 'Backspace' && textarea.selectionStart === 0 && textarea.selectionEnd === textarea.value.length) {
      // Prevent deleting all content when selecting all and pressing backspace
      e.preventDefault();
      return;
    }

    if (
      (e.key === 'Backspace' && position > 0 && isCursorInsideFixedSegment(position - 1)) ||
      (e.key === 'Delete' && isCursorInsideFixedSegment(position)) ||
      (e.key.length === 1 && isCursorInsideFixedSegment(position))
    ) {
      e.preventDefault();
    }
  };

  // Prevent selecting and modifying fixed segments
  const handleSelect = e => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    fixedSegments.forEach(segment => {
      const segmentStart = content.indexOf(`<${segment}>`);
      const segmentEnd = segmentStart + segment.length + 2;

      if (
        (start < segmentEnd && end > segmentStart) ||
        (start > segmentStart && start < segmentEnd) ||
        (end > segmentStart && end < segmentEnd)
      ) {
        textarea.setSelectionRange(start, end);
      }
    });
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.addEventListener('select', handleSelect);
    return () => {
      textarea.removeEventListener('select', handleSelect);
    };
  }, [content]);

  const handleOk = async () => {
    try {
      await form.validateFields();
      setReviewRequestModal(false);
      setSuccessRequestModal(true);

      if (!isEdit) {
        localStorage.setItem('text_customization_request_data', JSON.stringify({
          ...form.getFieldsValue(),
          language: lang,
          customizationType: 'advanced',
        }));
      } else {
        const response = await createTextCustomizationRequest([{
          ...form.getFieldsValue(),
          language: lang,
          customizationType: 'advanced',
          themeId: themeId
        }]);

        console.log('response', response);
      }

    } catch (error) {
      console.log('Validation failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const getApprovalStatus = lang => {
    const hasData =
      textCustomizationData &&
      textCustomizationData.length > 0 &&
      textCustomizationData.findIndex(item => item.language === lang) !== -1;

    if (hasData) {
      const data = textCustomizationData.find(item => item.language === lang);
      return data.status === 'in-progress';
    } else {
      return false;
    }
  };

  const renderHelpMessage = lang => {
    const button = (
      <Button style={{ paddingLeft: '0px' }} type="link" onClick={() => setReviewRequestModal(true)}>
        Need Changes? Request here
      </Button>
    );

    const defaultMessage = (
      <>
        The text area contains four predefined sentences that cannot be modified. However, you can insert new text at
        the beginning, middle, or end of the existing text.{' '}
        <a href="" target="_blank">
          Check IAB guidelines.{' '}
        </a>
        <span>If modifications to the predefined text are required, please submit a request for review.</span>
        <br />
        <>{button}</>
      </>
    );
    if (!textCustomizationData || textCustomizationDataLoading) {
      return defaultMessage;
    }
    const index =
      textCustomizationData &&
      textCustomizationData.length > 0 &&
      textCustomizationData.findIndex(item => item.language === lang);

    if (index !== -1) {
      const data = textCustomizationData[index];
      if (data.status === 'in-progress') {
        return (
          <span style={{ color: '#836A00' }}>
            We're reviewing your content to ensure it meets our guidelines. In the meantime, we've applied the standard
            text. Your custom content will go live once approved!
          </span>
        );
      } else if (data.status === 'approved') {
        return (
          <>
            <span style={{ color: '#836A00' }}>
              Your custom message has been applied. Modifications can only be requested again after 15 days from
              approval.
            </span>

            {hasFifteenDaysPassed(data) && <>{button}</>}
          </>
        );
      } else {
        return defaultMessage;
      }
    } else {
      return defaultMessage;
    }
  };

  return (
    <div style={{ marginBottom: '50px' }}>
      <Form.Item label="Privacy Body Copy" name="privacyBody" valuePropName="value" help={renderHelpMessage(lang)}>
        {reviewText[lang] ? (
          <>
            <textarea
              value={reviewText[lang]}
              rows="10"
              cols="80"
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
              }}
              disabled={true}
            />
          </>
        ) : (
          <textarea
            ref={textareaRef}
            value={reviewText || content}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            rows="10"
            cols="80"
            style={{
              width: '100%',
              padding: '10px',
              border: '1px solid #d9d9d9',
              borderRadius: '4px',
            }}
            disabled={getApprovalStatus(lang)}
          />
        )}
      </Form.Item>

      <Modal
        title="Text Change Request"
        open={reviewRequestModal}
        onClose={() => {
          form.resetFields();
          setReviewRequestModal(false);
        }}
        onCancel={() => {
          form.resetFields();
          setReviewRequestModal(false);
        }}
        onOk={handleOk}
        okButtonProps={{ htmlType: 'submit', loading }}
      >
        <Form className="p-4" layout="vertical" form={form} onFinish={values => console.log('Form values:', values)}>
          <Form.Item
            label="Proposed Text"
            name="customizationText"
            rules={[{ required: true, message: 'Please input the text you want to add!' }]}
          >
            <Input.TextArea rows={3} placeholder="Text you want to add" />
          </Form.Item>

          <Form.Item
            label="Reason to change text"
            name="reason"
            rules={[{ required: true, message: 'Please input the reason to change text!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={successRequestModal}
        onClose={() => {
          setSuccessRequestModal(false);
        }}
        onCancel={() => {
          setSuccessRequestModal(false);
        }}
        onOk={() => {
          setSuccessRequestModal(false);
          setReferenceId(null);
          form.resetFields();
          refetch();
        }}
        okButtonProps={{ htmlType: 'submit', loading }}
      >
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <img src={fileImage} alt="File" />

          <h3>Your Request is under Review</h3>

          {referenceId && <p>Reference Id: {referenceId}</p>}

          <p>We are reviewing your request and will notify you via email soon.</p>
        </div>
      </Modal>
    </div>
  );
};

export default TextAreaEditor;
