export const TEXT = {
    ANNOUCEMENT: {
        REPORTS: 'Great news! You can now access reports and data with our latest feature-giving you deeper insights at your fingertips.',
        COP: `"Consent or Pay" is Now Available! Stay compliant while unlocking a new revenue stream!`,
    },
    ACTIONS: {
        REPORTS: 'Go To Reports Now',
        COP: 'See How It Works',
        ACCESS_REQUEST_COP_SUBMITTED: 'Access Request Submitted – We’ll reach out shortly.'
    },
    MODAL: {
        COP_SUBHEADING: 'A new way to customize consent experiences and unlock revenue opportunities for publishers.',
        SUCCESS: `We've received your request to enable Consent or Pay on your account. Our team will reach out soon with the next steps.`,
        CONTACT_US: `If you have any urgent queries, feel free to`
    }
}