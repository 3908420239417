// @flow
import React, { useState, useEffect } from 'react';
import Card from 'antd/es/card';
import Checkbox from 'antd/es/checkbox';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Styles from '../../../../components/forms/themes/themesForm.module.scss';
import Select from '../../../../components/ui/select';
import FormStyles from '../../../../scss/_forms.module.scss';
import UIStyles from './uiSettings.module.scss';
import { SUPPORTED_LANGUAGES } from '../../../../utils/language';
import { Col, Collapse, Radio, Row, Tabs } from 'antd/es';

type Props = {
  disagreeButtonTextDisabled: boolean;
  customLinks: Array;
  translations: Object;
  validations: Object;
  setDataTestId: Function;
  onChange: Function;
  validateFields: Function;
  regulationEnabled: boolean;
  form: any;
}

const { Panel } = Collapse;

const ThemeComponent = ({themeModel, validations}) => {
  return (
    <div
      style={{
        margin: '20px 0px',
        padding: '20px',
        backgroundColor: '#F8FAFF',
        border: '1px solid #B3C7EE',
        borderRadius: '4px',
      }}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name={[themeModel, "globalBackgroundColor"]} label="Global Background Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={[themeModel, "titleTextColor"]} label="Title Text Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name={[themeModel, "bodyTextColor"]} label="Body Text Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={[themeModel, "buttonBackgroundColor"]} label="Button Background Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name={[themeModel, "buttonTextColor"]} label="Button Text Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={[themeModel, "buttonDisabledBackgroundColor"]} label="Disabled Button Background Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name={[themeModel, "buttonDisabledTextColor"]} label="Disabled Button Text Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={[themeModel, "linkTextColor"]} label="Link Test Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name={[themeModel, "infoButtonForegroundColor"]} label="Info Button Foreground Color" rules={validations.hexColor}>
            <Input type="text" />
          </Form.Item>
        </Col>
      </Row>

      <Collapse className={FormStyles['more_options']} expandIconPosition="end" defaultActiveKey={['1']}>
        <Panel header={<strong>More Options</strong>} key="1" className={FormStyles['more_options_panel']}>
          <div style={{ backgroundColor: '#F8FAFF', border: '1px solid #B3C7EE', padding: '10px', borderTop: 'none' }}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name={[themeModel, "tabBackgroundColor"]} label="Tab Background Color" rules={validations.hexColor}>
                  <Input type="text" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name={[themeModel, "tabTextColor"]} label="Tab Text Color" rules={validations.hexColor}>
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name={[themeModel, "searchBarBackgroundColor"]} label="Search Bar Background Color" rules={validations.hexColor}>
                  <Input type="text" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name={[themeModel, "searchBarForegroundColor"]} label="Search Bar Foreground Color" rules={validations.hexColor}>
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name={[themeModel, "dividerColor"]} label="Divider Color" rules={validations.hexColor}>
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={[themeModel, "menuTextColor"]} label="Menu Text Color" rules={validations.hexColor}>
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name={[themeModel, "toggleActiveColor"]} label="Toggle Active Color" rules={validations.hexColor}>
                  <Input type="text" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={[themeModel, "toggleInactiveColor"]} label="Toggle Inactive Color" rules={validations.hexColor}>
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

function UISettings({
  disagreeButtonTextDisabled,
  customLinks,
  validations,
  translations,
  setDataTestId,
  onChange,
  validateFields,
  regulationEnabled,
  form
}: Props) {
  const [customColorUsingPortal, setCustomizeColorsViaPortal] = useState();
  const [values, setValues] = useState({
    uxInitialScreenCustomLinksText0: '',
    uxInitialScreenCustomLinksUrl0: '',
    uxInitialScreenCustomLinksText1: '',
    uxInitialScreenCustomLinksUrl1: '',
    uxInitialScreenCustomLinksText2: '',
    uxInitialScreenCustomLinksUrl2: ''
  });
  const { Option } = Select;
  const [disableDisagree, setDisableDisagree] = useState(false);

  useEffect(() => {
    const links = {
      uxInitialScreenCustomLinksText0: '',
      uxInitialScreenCustomLinksUrl0: '',
      uxInitialScreenCustomLinksText1: '',
      uxInitialScreenCustomLinksUrl1: '',
      uxInitialScreenCustomLinksText2: '',
      uxInitialScreenCustomLinksUrl2: ''
    };

    if (customLinks && customLinks.length) {
      links.uxInitialScreenCustomLinksText0 = customLinks[0].text;
      links.uxInitialScreenCustomLinksUrl0 = customLinks[0].url;

      if (customLinks.length > 1) {
        links.uxInitialScreenCustomLinksText1 = customLinks[1].text;
        links.uxInitialScreenCustomLinksUrl1 = customLinks[1].url;

        if (customLinks.length > 2) {
          links.uxInitialScreenCustomLinksText2 = customLinks[2].text;
          links.uxInitialScreenCustomLinksUrl2 = customLinks[2].url;
        }
      }
    }

    setValues(links);
  }, []);

  useEffect(() => {
    if (form) {
      setCustomizeColorsViaPortal(form.getFieldValue('customColorUsingPortal'));
    }
  }, [form]);

  useEffect(() => {
    setDisableDisagree(disagreeButtonTextDisabled);
  }, [disagreeButtonTextDisabled]);

  const handleCustomLinkChange = (input, event) => {
    setValues({
      ...values,
      [input]: event.target.value
    });
  };

  const items = [
    {
      key: '1',
      label: 'Light Theme',
      children: <ThemeComponent themeModel="lightModeColors" validations={validations}/>,
    },
    {
      key: '2',
      label: 'Dark Theme',
      children: <ThemeComponent themeModel="darkModeColors" validations={validations}/>,
    }
  ];
  
  

  return (
    <Card title="UI settings">
      <Form.Item name="language" label="UI Language">
        <Select
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().includes(input.toLowerCase()) ||
            option.props.value.toLowerCase().includes(input.toLowerCase())
          }
          onChange={lang => onChange(preValues => ({ ...preValues, language: lang }))}
        >
          {SUPPORTED_LANGUAGES &&
            SUPPORTED_LANGUAGES.map(opt => (
              <Option key={opt.value} value={opt.value} disabled={opt.disabled}>
                {opt.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {regulationEnabled && (
        <>
          <Form.Item name="initScreenRejectButtonShowing" valuePropName="checked" className={Styles.disagreeButton}>
            <Checkbox
              data-testid={setDataTestId('initScreenRejectButtonShowing')}
              onChange={({ target }) => setDisableDisagree(!target.checked)}
            >
              SHOW DISAGREE BUTTON ON THE SUMMARY VIEW
            </Checkbox>
          </Form.Item>

          <Form.Item name="customColorUsingPortal" valuePropName="checked" className={Styles.disagreeButton}>
            <Checkbox
              data-testid={setDataTestId('customColorUsingPortal')}
              onChange={({ target }) => setCustomizeColorsViaPortal(target.checked)}
            >
              Would you like to customize colors via the portal?
            </Checkbox>
          </Form.Item>

          {customColorUsingPortal && (
            <Form.Item label="Select Your Preferred Theme" name="themeMode">
              <Radio.Group>
                <Radio value="LIGHT"> Light </Radio>
                <Radio value="DARK"> Dark </Radio>
                <Radio value="AUTO"> Auto </Radio>
              </Radio.Group>
            </Form.Item>
          )}

          {customColorUsingPortal && <Tabs defaultActiveKey="1" items={items} />}

          <Form.Item name="initScreenRejectButton" label="DISAGREE BUTTON TEXT" rules={validations.textInput}>
            <Input disabled={disableDisagree} placeholder={translations ? translations.disagree : 'DISAGREE'} />
          </Form.Item>
          <section
            className={`
      ${UIStyles.customLinks}
      ${Styles.disagreeButton}
      ${Styles.customLinks}
      ${Styles.form}
      ${FormStyles['wrapper-inline']}
    `}
          >
            <h5>
              CUSTOM FOOTER LINKS &nbsp;
              <span>The first entry in the custom footer list is reserved for your privacy policy.</span>
            </h5>
            <section className={FormStyles['inline-fields']}>
              <Form.Item
                name="uxInitialScreenCustomLinksText0"
                dependencies={['uxInitialScreenCustomLinksUrl0']}
                rules={validations.customLinksText('uxInitialScreenCustomLinksUrl0')}
              >
                <Input
                  placeholder="Link text"
                  onChange={event => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksText0', event);
                    validateFields(['uxInitialScreenCustomLinksUrl0']);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="uxInitialScreenCustomLinksUrl0"
                dependencies={['uxInitialScreenCustomLinksText0']}
                rules={validations.customLinksUrl('uxInitialScreenCustomLinksText0')}
              >
                <Input
                  placeholder="Link URL"
                  onChange={event => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksUrl0', event);
                    validateFields(['uxInitialScreenCustomLinksText0']);
                  }}
                />
              </Form.Item>
            </section>
            <section className={FormStyles['inline-fields']}>
              <Form.Item
                name="uxInitialScreenCustomLinksText1"
                dependencies={['uxInitialScreenCustomLinksUrl1']}
                rules={validations.customLinksText('uxInitialScreenCustomLinksUrl1')}
              >
                <Input
                  placeholder="Link text"
                  onChange={event => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksText1', event);
                    validateFields(['uxInitialScreenCustomLinksUrl1']);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="uxInitialScreenCustomLinksUrl1"
                dependencies={['uxInitialScreenCustomLinksText1']}
                rules={validations.customLinksUrl('uxInitialScreenCustomLinksText1')}
              >
                <Input
                  placeholder="Link URL"
                  onChange={event => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksUrl1', event);
                    validateFields(['uxInitialScreenCustomLinksText1']);
                  }}
                />
              </Form.Item>
            </section>
            <section className={FormStyles['inline-fields']}>
              <Form.Item
                name="uxInitialScreenCustomLinksText2"
                dependencies={['uxInitialScreenCustomLinksUrl2']}
                rules={validations.customLinksText('uxInitialScreenCustomLinksUrl2')}
              >
                <Input
                  placeholder="Link text"
                  onChange={event => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksText2', event);
                    validateFields(['uxInitialScreenCustomLinksUrl2']);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="uxInitialScreenCustomLinksUrl2"
                dependencies={['uxInitialScreenCustomLinksText2']}
                rules={validations.customLinksUrl('uxInitialScreenCustomLinksText2')}
              >
                <Input
                  placeholder="Link URL"
                  onChange={event => {
                    handleCustomLinkChange('uxInitialScreenCustomLinksUrl2', event);
                    validateFields(['uxInitialScreenCustomLinksText2']);
                  }}
                />
              </Form.Item>
            </section>
          </section>
        </>
      )}
    </Card>
  );
}

export default UISettings;
