import React, { useState, useEffect } from 'react';
import { Col, Form, Modal, Input, Radio, Row, Select, Button, TreeSelect } from 'antd';
import { CALLBACK_REDIRECT_METHOD, callbackOptions, callbackRedirectOptions, consentOrPayOptions } from './constants';
import { COUNTRY_CODES, EEA_AND_UK } from '../../../utils/constants';
import Styles from './consentOrPay.module.scss';
import validate from '../sites/syncValidation';
import { country3 } from '../../../utils/sites';
import { useTextCustomizationRequest } from '../../../data/hooks/utils';

const { SHOW_PARENT } = TreeSelect;

// @flow

type IConsentOrPayProps = {
  getFieldValue: Function,
  setFieldsValue: Function,
  validateFields: Function,
  handleChange: Function,
  values: Record<string, any>,
  type: string,
  onValuesChange: Function,
  isNonPersonalisedAdPresent: boolean,
};

const ConsentOrPay = ({
  getFieldValue,
  setFieldsValue,
  validateFields,
  values,
  handleChange,
  type = 'SITE',
  onValuesChange,
  isNonPersonalisedAdPresent,
}: IConsentOrPayProps) => {
  const validations = validate();

  const [modalOpen, setModalOpen] = useState(false);
  const isConsentOrPayEnabled = values.consentOrPayConfig && values.consentOrPayConfig.enabled;
  const isEditEnabled = isConsentOrPayEnabled;
  const europeanCountries = COUNTRY_CODES.filter(country => EEA_AND_UK.includes(country.value)).map(country => ({
    value: country3(country.value),
    title: country.label,
    key: country3(country.value),
  }));
  const [callbackType, setCallbackType] = useState(
    values.consentOrPayConfig ? values.consentOrPayConfig.callbackType : 'publisher',
  );
  const [consentOrPayRedirectMethod, setConsentOrPayRedirectMethod] = useState(CALLBACK_REDIRECT_METHOD.SAME_TAB);

  const [value, setValue] = useState(['eea']);

  const treeData = [
    {
      title: 'EEA Countries',
      value: 'eea',
      key: 'eea',
      children: europeanCountries,
    },
  ];

  const { data: themeCustomizationData, isLoading } = useTextCustomizationRequest(values.themeId);

  useEffect(() => {
    validateFields([['consentOrPayConfig', 'enabled']]);
  }, [isNonPersonalisedAdPresent]);

  const handleConsentOrPayChange = e => {
    if (e.target.value) {
      setModalOpen(true);
    } else {
      /* If the consent or pay is disabled, then set the main form value to false.
         If it is enabled, then set it to true only after saving the sub-form settings. */
      handleChange('consentOrPayConfig', { ...values.consentOrPayConfig, enabled: e.target.value });
      onValuesChange();
    }
  };

  const onCancel = () => {
    const currentSettings = values.consentOrPayConfig || {};
    setFieldsValue({ consentOrPayConfig: currentSettings });
    setModalOpen(false);
  };

  const onSave = () => {
    validateFields([
      ['consentOrPayConfig', 'countries'],
      ['consentOrPayConfig', 'callbacks', 'action1'],
      ['consentOrPayConfig', 'callbacks', 'action2'],
    ])
      .then(() => {
        let fields = getFieldValue('consentOrPayConfig');
        if (!fields.callbackRedirectMethod) {
          fields = { ...fields, callbackRedirectMethod: CALLBACK_REDIRECT_METHOD.SAME_TAB };
        }
        if (fields.countries.includes('eea')) {
          fields = { ...fields, countries: europeanCountries.map(country => country.value) };
        }
        onValuesChange();
        handleChange('consentOrPayConfig', fields);
        setModalOpen(false);
      })
      .catch(errorInfo => {
        console.log('errorInfo', errorInfo);
      });
  };

  const onChange = newValue => {
    setValue(newValue);
  };

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Please select',
    style: {
      width: '100%',
    },
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Form.Item
            name={['consentOrPayConfig', 'enabled']}
            label="Enable Consent or Pay"
            rules={validations.consentOrPayEnable(isNonPersonalisedAdPresent)}
            className={Styles.enabledItem}
          >
            <Radio.Group
              disabled={!isNonPersonalisedAdPresent}
              options={consentOrPayOptions}
              onChange={handleConsentOrPayChange}
            />
          </Form.Item>

          {getFieldValue('consentOrPayConfig').enabled &&
            themeCustomizationData &&
            themeCustomizationData.length > 0 && (
              <small style={{ color: '#836A00' }}>
                CoP content is currently under review. Standard text is being used until approval.
              </small>
            )}
        </Col>
        {isEditEnabled && (
          <Col>
            <Button type="link" onClick={() => setModalOpen(true)} className={Styles.EditButton}>
              {'Edit Settings'}
            </Button>
          </Col>
        )}
      </Row>

      <Modal
        title="Consent or Pay Settings"
        onClose={onCancel}
        onCancel={onCancel}
        open={modalOpen}
        closable={false}
        onOk={onSave}
        className={Styles.modalForm}
        width={600}
      >
        {getFieldValue('consentOrPayConfig').enabled && themeCustomizationData && themeCustomizationData.length > 0 && (
          <small style={{ color: '#836A00' }}>
            CoP content is currently under review. Standard text is being used until approval.
          </small>
        )}
        <Form.Item
          label="Which countries should be enabled for the Consent or Pay model?"
          name={['consentOrPayConfig', 'countries']}
          colon={false}
          labelCol={{ span: 24 }}
          required
          rules={validations.consentOrPayCountries}
        >
          <TreeSelect {...tProps} />
        </Form.Item>

        {type === 'SITE' && (
          <>
            <Form.Item
              label="How would you like to implement Consent or Pay?"
              colon={false}
              className={Styles.radioItem}
              name={['consentOrPayConfig', 'callbackType']}
              labelCol={{ span: 24 }}
            >
              <Radio.Group options={callbackOptions} onChange={e => setCallbackType(e.target.value)} />
            </Form.Item>

            {callbackType === 'publisher' && (
              <>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name={['consentOrPayConfig', 'callbacks', 'action1']}
                      label="Enter Re-direction URL for Subscribe"
                      colon={false}
                      required
                      rules={validations.consentOrPayAction1(callbackType)}
                    >
                      <Input placeholder="URL" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={['consentOrPayConfig', 'callbacks', 'action2']}
                      label="Enter Re-direction URL for Login"
                      colon={false}
                      rules={validations.consentOrPayAction2}
                    >
                      <Input placeholder="URL" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Form.Item
                    label="Should the click on Subscribe/Login button open the new URL is same tab?"
                    colon={false}
                    className={Styles.radioItem}
                    name={['consentOrPayConfig', 'callbackRedirectMethod']}
                    labelCol={{ span: 24 }}
                  >
                    <Radio.Group
                      defaultValue={consentOrPayRedirectMethod}
                      options={callbackRedirectOptions}
                      onChange={e => setConsentOrPayRedirectMethod(e.target.value)}
                    />
                  </Form.Item>
                </Row>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default ConsentOrPay;
