const TEXT = {
  GDPR_LABEL: 'European regulation (GDPR)',
  USP_LABEL: 'US regulation',
  BRAZIL: 'Brazil',
  CANADA: 'Canada',
  KOR: 'Korea',
  JPN: 'Japan',
  TWN: 'Taiwan',
  IDN: 'Indonesia',
  IND: 'India',
  VNM: 'Vietnam',
  THA: 'Thailand',
  HKG: 'Hong Kong',
  SGP: 'Singapore',
  MYS: 'Malaysia',
  PHL: 'Philippines',
  EEA: 'EEA',
  WORLDWIDE: 'Worldwide',
  USA: 'USA',
  NEVER: 'Never',
};

export default TEXT;
