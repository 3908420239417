import {
  BRA,
  BRAZIL,
  CAN,
  CANADA,
  CHILD_PURPOSE_ID,
  EEA,
  GBC_CMP_VERSION,
  GDPR,
  GPP_CMP_VERSION,
  HONG_KONG,
  INDIA,
  INDONESIA,
  JAPAN,
  KOREA,
  LATEST_CMP_VERSION,
  MALAYSIA,
  NEVER,
  OPT_OUT_PURPOSES,
  PHILIPPINES,
  SENSITIVE_PURPOSE_ID,
  SINGAPORE,
  TAIWAN,
  THAILAND,
  USA,
  USP,
  VIETNAM,
  WORLDWIDE,
} from './constants';
import TEXT from './text';
import SITETOOLTIPS from './toolTipsMessages';

export const isLatestCMP = cmpVersion => cmpVersion === 'latest' || cmpVersion === LATEST_CMP_VERSION;

export const isGBCSupported = cmpVersion => cmpVersion >= GBC_CMP_VERSION;

export const isGPPSupported = cmpVersion => cmpVersion >= GPP_CMP_VERSION;

export const getRegulationOptions = consentLocations => {
  const isDisabled = values => values.some(value => consentLocations.includes(value));

  return [
    {
      label: TEXT.GDPR_LABEL,
      value: GDPR,
      disabled: isDisabled([WORLDWIDE, EEA]),
      tooltip: SITETOOLTIPS.gdpr,
    },
    {
      label: TEXT.USP_LABEL,
      value: USP,
      disabled: isDisabled([WORLDWIDE, EEA, USA, BRAZIL, CANADA, TAIWAN, KOREA, JAPAN, INDONESIA, INDIA, VIETNAM, THAILAND, HONG_KONG, SINGAPORE, MALAYSIA, PHILIPPINES ]),
      tooltip: SITETOOLTIPS.US_regulation,
    },
  ];
};

const createConsentLocationOptions = (consentLocations, values) => {
  const isDisabled = values => values.some(value => consentLocations.includes(value));

  return values.map(({ value, label, disabledValues }) => {
    return {
      value,
      label: label,
      disabled: isDisabled(disabledValues),
    };
  });
};

export const getConsentLocationOptions = consentLocations => {
  const values = [
    { value: EEA, label: TEXT.EEA, disabledValues: [WORLDWIDE, NEVER] },
    { value: WORLDWIDE, label: TEXT.WORLDWIDE, disabledValues: [EEA, USA, NEVER] },
    { value: USA, label: TEXT.USA, disabledValues: [WORLDWIDE, NEVER] },
    { value: NEVER, label: TEXT.NEVER, disabledValues: [WORLDWIDE, EEA, USA] },
    { value: BRAZIL, label: TEXT.BRAZIL, disabledValues: [WORLDWIDE, NEVER] },
    { value: CANADA, label: TEXT.CANADA, disabledValues: [WORLDWIDE, NEVER] },
    { value: KOREA, label: TEXT.KOR, disabledValues: [WORLDWIDE, NEVER] },
    { value: JAPAN, label: TEXT.JPN, disabledValues: [WORLDWIDE, NEVER] },
    { value: TAIWAN, label: TEXT.TWN, disabledValues: [WORLDWIDE, NEVER] },
    { value: INDONESIA, label: TEXT.IDN, disabledValues: [WORLDWIDE, NEVER] },
    { value: INDIA, label: TEXT.IND, disabledValues: [WORLDWIDE, NEVER] },
    { value: VIETNAM, label: TEXT.VNM, disabledValues: [WORLDWIDE, NEVER] },
    { value: THAILAND, label: TEXT.THA, disabledValues: [WORLDWIDE, NEVER] },
    { value: HONG_KONG, label: TEXT.HKG, disabledValues: [WORLDWIDE, NEVER] },
    { value: SINGAPORE, label: TEXT.SGP, disabledValues: [WORLDWIDE, NEVER] },
    { value: MALAYSIA, label: TEXT.MYS, disabledValues: [WORLDWIDE, NEVER] },
    { value: PHILIPPINES, label: TEXT.PHL, disabledValues: [WORLDWIDE, NEVER] },
  ];

  if (!consentLocations) return createConsentLocationOptions([], values);

  return createConsentLocationOptions(consentLocations, values);
};

export const getAppConsentLocationOptions = consentLocations => {
  const values = [
    { value: EEA, label: TEXT.EEA, disabledValues: [WORLDWIDE, NEVER] },
    { value: WORLDWIDE, label: TEXT.WORLDWIDE, disabledValues: [EEA, USA, NEVER] },
    { value: USA, label: TEXT.USA, disabledValues: [WORLDWIDE, NEVER] },
    { value: NEVER, label: TEXT.NEVER, disabledValues: [WORLDWIDE, EEA, USA] },
    { value: BRA, label: TEXT.BRAZIL, disabledValues: [WORLDWIDE, NEVER] },
    { value: CAN, label: TEXT.CANADA, disabledValues: [WORLDWIDE, NEVER] },
    { value: KOREA, label: TEXT.KOR, disabledValues: [WORLDWIDE, NEVER] },
    { value: JAPAN, label: TEXT.JPN, disabledValues: [WORLDWIDE, NEVER] },
    { value: TAIWAN, label: TEXT.TWN, disabledValues: [WORLDWIDE, NEVER] },
    { value: INDONESIA, label: TEXT.IDN, disabledValues: [WORLDWIDE, NEVER] },
    { value: INDIA, label: TEXT.IND, disabledValues: [WORLDWIDE, NEVER] },
    { value: VIETNAM, label: TEXT.VNM, disabledValues: [WORLDWIDE, NEVER] },
    { value: THAILAND, label: TEXT.THA, disabledValues: [WORLDWIDE, NEVER] },
    { value: HONG_KONG, label: TEXT.HKG, disabledValues: [WORLDWIDE, NEVER] },
    { value: SINGAPORE, label: TEXT.SGP, disabledValues: [WORLDWIDE, NEVER] },
    { value: MALAYSIA, label: TEXT.MYS, disabledValues: [WORLDWIDE, NEVER] },
    { value: PHILIPPINES, label: TEXT.PHL, disabledValues: [WORLDWIDE, NEVER] },
  ];

  return createConsentLocationOptions(consentLocations, values);
};

const isValueDefined = value => typeof value !== 'undefined' && value !== null;

export const getConsentLocation = (consentLocations, regulation, requireConsentFrom) => {
  if (requireConsentFrom) {
    if (consentLocations && consentLocations.length > 0) {
      return consentLocations;
    }

    const locations = [];
    if (requireConsentFrom === 'always') {
      locations.push(WORLDWIDE);
      return locations;
    }
    if (requireConsentFrom === 'never') {
      locations.push(NEVER);
      return locations;
    }
    if (requireConsentFrom === 'inEU') {
      locations.push(EEA);
    }
    if (regulation.includes(USP)) {
      locations.push(USA);
    }
    return locations;
  }
  return [WORLDWIDE];
};

export const getMSPAInitData = current => {
  const {
    mspaOptOutPurposeIds,
    gdprEncodingMode,
    mspaJurisdiction,
    isCoveredTransaction,
    ccpaViaUsp,
    mspaSensitiveDataPurposeIds,
    mspaAutoPopUp,
    consentLocations,
    privacyModes,
    requireConsentFrom,
  } = current;
  const currentMspaOptOutPurposeIds = mspaOptOutPurposeIds || [];
  const mspaPurposeIds =
    currentMspaOptOutPurposeIds.length > 0
      ? currentMspaOptOutPurposeIds.filter(id => id !== SENSITIVE_PURPOSE_ID && id !== CHILD_PURPOSE_ID)
      : OPT_OUT_PURPOSES;
  const mspaSensitiveDataProcessing =
    currentMspaOptOutPurposeIds.length > 0 ? currentMspaOptOutPurposeIds.indexOf(SENSITIVE_PURPOSE_ID) !== -1 : false;
  const mspaChildSensitiveDataProcessing =
    currentMspaOptOutPurposeIds.length > 0 ? currentMspaOptOutPurposeIds.indexOf(CHILD_PURPOSE_ID) !== -1 : false;

  const updatedConsentLocation = getConsentLocation(consentLocations, privacyModes, requireConsentFrom);

  return {
    gdprEncodingMode: gdprEncodingMode || 'TCF_AND_GPP',
    isCoveredTransaction: isValueDefined(isCoveredTransaction) ? isCoveredTransaction : false,
    mspaJurisdiction: mspaJurisdiction || 'STATE_AND_NATIONAL',
    ccpaViaUsp: isValueDefined(ccpaViaUsp) ? ccpaViaUsp : false,
    mspaPurposeIds,
    mspaSensitiveDataProcessing,
    mspaChildSensitiveDataProcessing,
    mspaSensitiveDataPurposeIds: mspaSensitiveDataPurposeIds || [],
    mspaAutoPopUp: isValueDefined(mspaAutoPopUp) ? mspaAutoPopUp : true,
    consentLocations: updatedConsentLocation,
  };
};

export const getIsNonPersonalisedAdPresent = (vendorsList, blockedVendors) => {
  if (!vendorsList || !blockedVendors) {
    return true;
  }

  const filterVendors = vendorsList.filter(vendor => !blockedVendors.includes(vendor.id));
  const nonPersonalisedPurposes = [2];
  const nonPersonalisedSpecialPurposes = [1, 2, 3];

  const isNonPersonalisedAdPresent = filterVendors.some(vendor => {
    const { purposes, specialPurposes } = vendor;

    return (
      purposes.some(purpose => nonPersonalisedPurposes.includes(purpose)) ||
      specialPurposes.some(specialPurpose => nonPersonalisedSpecialPurposes.includes(specialPurpose))
    );
  });
  return isNonPersonalisedAdPresent;
};

export function downloadCsv(csvContent, filename = 'data.csv') {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function jsonToCsv(jsonArray) {
  const headers = Object.keys(jsonArray[0]).join(',');
  const rows = jsonArray.map(obj => Object.values(obj).join(',')).join('\n');

  return `${headers}\n${rows}`;
}

export function csvToJSON(csvContent) {
  const lines = csvContent.split('\n');
  const result = [];
  const headers = lines[0].split(',');

  const maxListLength = lines.length > 100 ? 100 : lines.length;
  for (let i = 1; i < maxListLength; i += 1) {
    const obj = {};
    const currentline = lines[i].split(',');

    for (let j = 0; j < headers.length; j += 1) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }
  return result;
}
