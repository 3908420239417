// @flow

import './styles.scss';

// @flow
import React, { useState } from 'react';

import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Space from 'antd/es/space';
import classNames from 'classnames';
import uid from 'uid';
import CustomSettings from '../custom-settings';
import Select from '../../../../components/ui/select';
import { CONSENT_LOCATIONS, TYPES_FILER, YES_NO_FILTER } from '../../constants';
import {
  CONSENT_SCOPE,
  COUNTRY_CODES,
  REGULATION_MODES_V2,
} from '../../../../utils/constants';
import { getConsentLocationOptions } from '../../../../utils/property';

const { Option } = Select;

interface iBulkUpdateFilter {
  cmpVersionV2: { list: Array<Object> },
  themes: { list: Array<Object> },
  filterSites: Function,
  fields: Record<any>,
  handleChange: Function,
}

interface iFieldSet {
  label?: string;
  required?: boolean;
  children: JSX.Element;
}

const FieldSet = (props: iFieldSet) => {
  const { label, children, required } = props;
  return (
    <div className="qc-fieldset">
      <span className={'qc-fieldset-required'}>{required && '*'}</span>
      <span className={classNames({
        'qc-fieldset-label': label,
        'qc-fieldset-label--no-content': !label
      })}
      >
        {label}
      </span>
      {children}
    </div>
  );
};

const BulkUpdateFilter = ({
  themes,
  cmpVersionV2,
  filterSites,
  fields,
  handleChange,
}: iBulkUpdateFilter) => {
  const [showCustomSettings, setShowCustomSettings] = useState<boolean>(false);
  // its needed to calculate the tag overflow on multiselect fields..
  const selectWidthStyle = { width: '190px' };

  const setLegalBasisFilter = (value) => {
    if (value === 'No') {
      handleChange('customizedFilters', null);
    }
    handleChange('legalBasisFilter', value);
  };

  const shouldShowCustomSettings = () => fields.legalBasisFilter === 'Yes';

  const applyCustomizedFilters = (filters) => {
    handleChange('customizedFilters', filters);
    setShowCustomSettings(false);
  };

  const applyFilter = () => {
    filterSites(fields);
  };

  const selectElementFilter = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <form autoComplete="off" className="bulk-update-filter">
      <div className="bulk-update-filter__column">
        <FieldSet label="Type" required>
          <Select
            style={selectWidthStyle}
            onChange={value => handleChange('typeFilter', value)}
            value={fields.typeFilter}
          >
            {TYPES_FILER.map(item => <Option key={uid()} value={item}>{item}</Option>)}
          </Select>
        </FieldSet>
        <FieldSet label="Regulation" required>
          <Select
            style={selectWidthStyle}
            onChange={value => handleChange('regulationFilter', value)}
            value={fields.regulationFilter}
          >
            {
              REGULATION_MODES_V2.map(
                item => <Option key={uid()} value={item.value}>{item.key}</Option>
              )
            }
          </Select>
        </FieldSet>
        <FieldSet label="Name">
          <Input onChange={e => handleChange('nameFilter', e.target.value)} value={fields.nameFilter} />
        </FieldSet>
        <FieldSet label="CMP version">
          <Select
            mode="multiple"
            maxTagCount="responsive"
            style={selectWidthStyle}
            allowClear
            onChange={value => handleChange('versionFilter', value)}
            value={fields.versionFilter}
            loading={cmpVersionV2.pending}
            showArrow
          >
            {
              cmpVersionV2.list.map((version) => {
                const value = version.includes('latest') ? 'latest' : version;
                return (<Option key={uid()} value={value}>{version}</Option>);
              })
            }
          </Select>
        </FieldSet>
      </div>
      <div className="bulk-update-filter__column">
        <FieldSet label="Theme">
          <Select
            mode="multiple"
            maxTagCount="responsive"
            style={selectWidthStyle}
            allowClear
            onChange={value => handleChange('themeFilter', value)}
            value={fields.themeFilter}
            filterOption={selectElementFilter}
            showArrow
          >
            {themes.list.map(item => <Option key={uid()} value={item.themeId}>{item.themeName}</Option>)}
          </Select>
        </FieldSet>
        <FieldSet label="Publisher Country">
          <Select
            mode="multiple"
            maxTagCount="responsive"
            style={selectWidthStyle}
            allowClear
            showArrow
            onChange={value => handleChange('countryFilter', value)}
            value={fields.countryFilter}
            filterOption={selectElementFilter}
          >
            {COUNTRY_CODES.map(item => <Option key={uid()} value={item.value}>{item.label}</Option>)}
          </Select>
        </FieldSet>
        <FieldSet label="Google Basic Consent">
          <Select
            style={selectWidthStyle}
            allowClear
            onChange={value => handleChange('gbcFilter', value)}
            value={fields.gbcFilter}
          >
            {YES_NO_FILTER.map(item => <Option key={uid()} value={item}>{item}</Option>)}
          </Select>
        </FieldSet>
        <FieldSet label="Google Vendors">
          <Select
            style={selectWidthStyle}
            allowClear
            onChange={value => handleChange('googleFilter', value)}
            value={fields.googleFilter}
          >
            {YES_NO_FILTER.map(item => <Option key={uid()} value={item}>{item}</Option>)}
          </Select>
        </FieldSet>
      </div>
      <div className="bulk-update-filter__column">
        <FieldSet label="User consent location">
          <Select
            mode="multiple"
            maxTagCount="responsive"
            style={selectWidthStyle}
            allowClear
            showArrow
            onChange={value => handleChange('consentLocationsFilter', value)}
            value={fields.consentLocationsFilter}
          >
            {getConsentLocationOptions().map(item => <Option key={uid()} value={item.value}>{item.label}</Option>)}
          </Select>
        </FieldSet>
        <FieldSet label="Consent Scope">
          <Select
            mode="multiple"
            maxTagCount="responsive"
            style={selectWidthStyle}
            allowClear
            showArrow
            onChange={value => handleChange('consentScopeFilter', value)}
            value={fields.consentScopeFilter}
          >
            {CONSENT_SCOPE.map(item => <Option key={uid()} value={item.value}>{item.label}</Option>)}
          </Select>
        </FieldSet>
        <FieldSet label="Legal basis">
          <Select
            style={selectWidthStyle}
            allowClear
            onChange={setLegalBasisFilter}
            value={fields.legalBasisFilter}
          >
            {YES_NO_FILTER.map(item => <Option key={uid()} value={item}>{item}</Option>)}
          </Select>
        </FieldSet>
        <FieldSet>
          <Space direction="vertical">
            <Button
              disabled={!shouldShowCustomSettings()}
              onClick={() => setShowCustomSettings(true)}
            >
              Customize settings
            </Button>
            <Button
              type="primary"
              onClick={applyFilter}
              disabled={fields.typeFilter === undefined}
            >
              Apply Filter
            </Button>
          </Space>
        </FieldSet>
      </div>
      <CustomSettings
        applyChanges={applyCustomizedFilters}
        cancelChanges={() => setShowCustomSettings(false)}
        showModal={showCustomSettings}
        customizedFilters={fields.customizedFilters}
        filterMode
      />
    </form>
  );
};

export default BulkUpdateFilter;
