import { buildError } from '../../utils/reducer';
import { GVL_VERSION_3 } from '../../utils/constants';
import { VENDORS } from '../actions';

const defState = {
  v2: {
    data: {},
    error: {
      code: 200,
      message: ''
    },
    pending: false
  },
  v3: {
    data: {},
    error: {
      code: 200,
      message: ''
    },
    pending: false
  },
  googleVendorWhiteList: { pending: false, error: false }
};

export function fetchAllVendors(pCode, GVLVersion) {
  return GVLVersion === GVL_VERSION_3 ? {
    type: VENDORS.V3.GET_ALL.FETCH,
    payload: { pCode, GVLVersion },
  } : {
    // Remove after v2 is deprecated
    type: VENDORS.V2.GET_ALL.FETCH,
    payload: { pCode, GVLVersion },
  };
}

export function toggleBlock(pCode, version, list, alert, updateChoiceJs, GVLVersion, blockedByDefault) {
  if (GVLVersion === GVL_VERSION_3) {
    console.log('duck toggleBlock', { pCode, version, list, alert, updateChoiceJs, GVLVersion, blockedByDefault });
    return {
      type: VENDORS.V3_TOGGLE_BLOCK.FETCH,
      payload: { pCode, version, list, alert, updateChoiceJs, GVLVersion, blockedByDefault }
    };
  }
  return {
    type: VENDORS.TOGGLE_BLOCK.FETCH,
    payload: { pCode, version, list, alert, updateChoiceJs, GVLVersion, blockedByDefault }
  };
}

export function toggleGoogleWhiteList(pCode, whitelistGoogleVendorsIds) {
  return {
    type: VENDORS.TOGGLE_GOOGLE_WHITE_LIST.FETCH,
    payload: { pCode, whitelistGoogleVendorsIds }
  };
}

export function toggleGoogleWhiteListV3(pCode, whitelistGoogleVendorsIds) {
  return {
    type: VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.FETCH,
    payload: { pCode, whitelistGoogleVendorsIds }
  };
}

function vendorsReducer(state = defState, action) {
  switch (action.type) {
    // Remove actions until the V3 actions starts, when V2 is deprecated
    case VENDORS.TOGGLE_BLOCK.PENDING:
    case VENDORS.V2.GET_ALL.PENDING: {
      return { ...state, v2: { ...state.v2, data: {}, pending: true } };
    }
    case VENDORS.V2.GET_ALL.FULFILLED: {
      return { ...state, v2: { ...state.v2, data: action.payload, pending: false } };
    }
    case VENDORS.TOGGLE_BLOCK.REJECTED:
    case VENDORS.V2.GET_ALL.REJECTED: {
      return { ...state, v2: { ...state.v2, pending: false, error: buildError(action.payload), data: {} } };
    }
    case VENDORS.TOGGLE_BLOCK.FULFILLED: {
      return { ...state, v2: { ...state.v2, blockedVendors: action.payload, pending: false } };
    }
    case VENDORS.TOGGLE_GOOGLE_WHITE_LIST.FETCH:
    case VENDORS.TOGGLE_GOOGLE_WHITE_LIST.PENDING: {
      return { ...state, googleVendorWhiteList: { pending: true, error: false } };
    }
    case VENDORS.TOGGLE_GOOGLE_WHITE_LIST.REJECTED: {
      return { ...state, googleVendorWhiteList: { pending: false, error: true } };
    }
    case VENDORS.TOGGLE_GOOGLE_WHITE_LIST.FULFILLED: {
      return { ...state, googleVendorWhiteList: { pending: false, error: false } };
    }
    // V3
    case VENDORS.V3_TOGGLE_BLOCK.PENDING:
    case VENDORS.V3.GET_ALL.PENDING: {
      return { ...state, v3: { ...state.v3, data: {}, pending: true } };
    }
    case VENDORS.V3.GET_ALL.FULFILLED: {
      return { ...state, v3: { ...state.v3, data: action.payload, pending: false } };
    }
    case VENDORS.V3_TOGGLE_BLOCK.REJECTED:
    case VENDORS.V3.GET_ALL.REJECTED: {
      return { ...state, v3: { ...state.v3, pending: false, error: buildError(action.payload), data: {} } };
    }
    case VENDORS.V3_TOGGLE_BLOCK.FULFILLED: {
      return { ...state, v3: { ...state.v3, blockedVendors: action.payload, pending: false } };
    }
    case VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.FETCH:
    case VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.PENDING: {
      return { ...state, googleVendorWhiteList: { pending: true, error: false } };
    }
    case VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.REJECTED: {
      return { ...state, googleVendorWhiteList: { pending: false, error: true } };
    }
    case VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.FULFILLED: {
      return { ...state, googleVendorWhiteList: { pending: false, error: false } };
    }

    default: {
      return state;
    }
  }
}

export default vendorsReducer;
