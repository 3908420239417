/* eslint-disable import/prefer-default-export */
import { requestCustomizationV2, requestCustomizationV3 } from '../../utils/requests';
import { GVL_VERSION_3 } from '../../utils/constants';

const url = '/vendors';

export function getAll([pCode, GVLVersion]) {
  if (GVLVersion === GVL_VERSION_3) {
    return requestCustomizationV3
      .get(`${url}/pCode/${pCode}`)
      .then(res => ({ res }))
      .catch(err => ({ err }));
  }
  return requestCustomizationV2
    .get(`${url}/pCode/${pCode}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function toggleBlock([pCode, version, list, GVLVersion, blockedByDefault]) {
  const body = { pCode, version, GVLVersion };
  if (blockedByDefault) body.whitelistedVendorsIds = list;
  else body.blockedVendorsIds = list;
  body.blockedByDefault = blockedByDefault;
  console.log('toggleBlock', body);
  if (GVLVersion === GVL_VERSION_3) {
    return requestCustomizationV3
      .put(`${url}/pCode/${pCode}`, body)
      .then(res => ({ res }))
      .catch(err => ({ err }));
  }
  return requestCustomizationV2
    .put(`${url}/pCode/${pCode}`, body)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function toggleGoogleWhiteList([pCode, whitelistGoogleVendorsIds]) {
  const body = { pCode, googleEnabled: true, whitelistGoogleVendorsIds };

  return requestCustomizationV2
    .put(`${url}/pCode/${pCode}`, body)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
export function toggleGoogleWhiteListV3([pCode, whitelistGoogleVendorsIds]) {
  const body = { pCode, googleEnabled: true, whitelistGoogleVendorsIds };

  return requestCustomizationV3
    .put(`${url}/pCode/${pCode}`, body)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
