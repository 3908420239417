/* eslint-disable import/prefer-default-export */
import TRANSLATIONS from '../__mocks__/translations';

export function autoDetectSupportedLanguage(lang) {
  const supportedLangs = [
    'en', 'fr', 'de', 'it', 'es', 'da', 'nl', 'el', 'hu', 'pt', 'ro', 'fi', 'pl', 'sk', 'sv', 'ru',
    'ar', 'fa', 'uk', 'tr', 'ja', 'id', 'ko', 'zh'
  ];
  let response = 'en';

  const splitLang = l => (l && l.length > 2 ? l.split('-')[0] : l);

  if (supportedLangs.includes(splitLang(lang))) {
    response = lang;
  }

  if (
    !(supportedLangs.includes(splitLang(lang)))
    && supportedLangs.includes(splitLang(navigator.language))
  ) {
    response = splitLang(navigator.language);
  }

  return response;
}

/*
    for variants of some languages as portuguese this CMP just offer one unique language in all text
    less all text are handle by GVL (purposes text for example)
    So for example for pt-pt we just back pt ( for Inmobi CMP text )
    and pt-pt to GVL for purposes in Portuguese from Portugal
  */
export const getTranslationsForLang = (language) => {
  if (language === 'auto') {
    return TRANSLATIONS.en;
  }
  return TRANSLATIONS[language.split('-')[0]];
};

export const SUPPORTED_LANGUAGES = [
  {
    "value": "auto",
    "label": "AUTO DETECT LANGUAGE"
  },
  {
    "value": "id",
    "label": "BAHASA"
  },
  {
    "value": "bg",
    "label": "BULGARIAN"
  },
  {
    "value": "hr",
    "label": "CROATIAN"
  },
  {
    "value": "cs",
    "label": "CZECH"
  },
  {
    "value": "da",
    "label": "DANISH"
  },
  {
    "value": "nl",
    "label": "DUTCH"
  },
  {
    "value": "en",
    "label": "ENGLISH"
  },
  {
    "value": "et",
    "label": "ESTONIAN"
  },
  {
    "value": "fi",
    "label": "FINNISH"
  },
  {
    "value": "fr",
    "label": "FRENCH"
  },
  {
    "value": "de",
    "label": "GERMAN"
  },
  {
    "value": "el",
    "label": "GREEK"
  },
  {
    "value": "hu",
    "label": "HUNGARIAN"
  },
  {
    "value": "it",
    "label": "ITALIAN"
  },
  {
    "value": "ja",
    "label": "JAPANESE"
  },
  {
    "value": "ko",
    "label": "KOREAN"
  },
  {
    "value": "lv",
    "label": "LATVIAN"
  },
  {
    "value": "lt",
    "label": "LITHUANIAN"
  },
  {
    "value": "mt",
    "label": "MALTESE"
  },
  {
    "value": "zh",
    "label": "MANDARIN"
  },
  {
    "value": "no",
    "label": "NORWEGIAN"
  },
  {
    "value": "pl",
    "label": "POLISH"
  },
  {
    "value": "pt-br",
    "label": "PORTUGUESE - BRAZIL"
  },
  {
    "value": "pt-pt",
    "label": "PORTUGUESE - PORTUGAL"
  },
  {
    "value": "ro",
    "label": "ROMANIAN"
  },
  {
    "value": "ru",
    "label": "RUSSIAN"
  },
  {
    "value": "sk",
    "label": "SLOVAK"
  },
  {
    "value": "sl",
    "label": "SLOVENIAN"
  },
  {
    "value": "es",
    "label": "SPANISH"
  },
  {
    "value": "sv",
    "label": "SWEDISH"
  },
  {
    "value": "tr",
    "label": "TURKISH"
  },
  {
    "value": "uk",
    "label": "UKRAINIAN"
  },
  {
    "value": "hi",
    "label": "HINDI"
  },
  {
    "value": "vi",
    "label": "VIETNAMESE"
  },
  {
    value: "th",
    "label": "THAI"
  },
  {
    value: "ms",
    "label": "MALAY"
  }
];

export const DISABLED_COP_LANGUAGES = ['auto', 'id', 'ja', 'ko', 'zh'];