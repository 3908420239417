/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { sortBy } from '../../utils/arrays';
import { ALERTS, VENDORS } from '../actions';
import { ALERT_IDENTIFIERS, GVL_VERSION_3 } from '../../utils/constants';
import { toggleBlock, getAll, toggleGoogleWhiteList, toggleGoogleWhiteListV3 } from '../requests/vendors';

export function* fetchAllVendors({ payload: { pCode, GVLVersion } }) {
  const isGVLVersion3 = GVLVersion === GVL_VERSION_3;
  if (isGVLVersion3) {
    yield put({ type: VENDORS.V3.GET_ALL.PENDING });
  } else {
    yield put({ type: VENDORS.V2.GET_ALL.PENDING });
  }
  const { res, err } = yield call(getAll, [pCode, GVLVersion]);

  if (res) {
    const nextData = { ...res.data };
    nextData.vendors = sortBy(nextData.vendors, 'name');
    const nonBlockVendors = nextData.vendors.every(vendor => !nextData.blockedVendors.includes(vendor.id));
    if (nonBlockVendors && res.data.gvlSpecificationVersion === 3) {
      yield put({
        type: ALERTS.ADD,
        payload: {
          type: 'warning',
          description: 'You have not selected/blocked vendors in the vendor configuration section. '
           + 'We recommend you review and update the IAB 2.2 vendor list based on your needs',
          closable: true,
          autoClose: false,
          id: ALERT_IDENTIFIERS.VENDOR_V3_LIST_BLOCK_LIST,
        }
      });
    }
    if (isGVLVersion3) {
      yield put({ type: VENDORS.V3.GET_ALL.FULFILLED, payload: nextData });
    } else {
      yield put({ type: VENDORS.V2.GET_ALL.FULFILLED, payload: nextData });
    }
  } else if (isGVLVersion3) {
    yield put({ type: VENDORS.V3.GET_ALL.REJECTED, payload: err.response });
  } else {
    yield put({ type: VENDORS.V2.GET_ALL.REJECTED, payload: err.response });
  }
}

export function* toggleVendorBlock({ payload: { pCode, version, list, alert, GVLVersion, blockedByDefault } }) {
  const isGVLVersion3 = GVLVersion === GVL_VERSION_3;
  if (isGVLVersion3) {
    yield put({ type: VENDORS.V3_TOGGLE_BLOCK.PENDING });
  } else {
    yield put({ type: VENDORS.TOGGLE_BLOCK.PENDING });
  }
  const blockCall = yield call(toggleBlock, [pCode, version, list, GVLVersion, blockedByDefault]);
  if (blockCall.res) {
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: alert.type,
        message: alert.message,
        description: alert.description,
        closable: true,
        autoClose: true
      }
    });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'warning',
        description: 'Selectable stacks have changed based on your new vendor configuration, '
          + 'please reselect a new stack configuration '
          + '(go to Purpose Configuration in the Themes page / Apps page to update that configuration).',
        closable: true,
        autoClose: true
      }
    });
    if (isGVLVersion3) {
      yield put({ type: VENDORS.V3.GET_ALL.FETCH, payload: { pCode, GVLVersion: GVL_VERSION_3 } });
    } else {
      yield put({ type: VENDORS.V2.GET_ALL.FETCH, payload: { pCode } });
    }
  } else if (isGVLVersion3) {
    yield put({ type: VENDORS.V3_TOGGLE_BLOCK.REJECTED, payload: blockCall.err.response });
  } else {
    yield put({ type: VENDORS.V2.TOGGLE_BLOCK.REJECTED, payload: blockCall.err.response });
  }
}


export function* toggleGoogleVendorsWhitelist({ payload: { pCode, whitelistGoogleVendorsIds } }) {
  yield put({ type: VENDORS.TOGGLE_GOOGLE_WHITE_LIST.PENDING });

  const res = yield call(toggleGoogleWhiteList, [pCode, whitelistGoogleVendorsIds]);
  if (res) {
    yield put({ type: VENDORS.V2.GET_ALL.FETCH, payload: { pCode } });
    yield put({ type: VENDORS.TOGGLE_GOOGLE_WHITE_LIST.FULFILLED });
  } else {
    yield put({ type: VENDORS.TOGGLE_GOOGLE_WHITE_LIST.REJECTED });
  }
}

export function* toggleGoogleVendorsWhitelistV3({ payload: { pCode, whitelistGoogleVendorsIds } }) {
  yield put({ type: VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.PENDING });

  const res = yield call(toggleGoogleWhiteListV3, [pCode, whitelistGoogleVendorsIds]);
  if (res) {
    yield put({ type: VENDORS.V3.GET_ALL.FETCH, payload: { pCode, GVLVersion: 3 } });
    yield put({ type: VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.FULFILLED });
  } else {
    yield put({ type: VENDORS.V3_TOGGLE_GOOGLE_WHITE_LIST.REJECTED });
  }
}
