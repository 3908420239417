import { getPremiumSites } from '../services/sites';
import { getThemeCustomizationRequest } from '../services/themes';
import { useQueryWrapper } from './common';
// @flow

const TEXT_CUSTOMIZATION_REQUEST_QUERY = 'textCustomizationRequest';

export const useTextCustomizationRequest = (themeId: string) => {
  return useQueryWrapper([TEXT_CUSTOMIZATION_REQUEST_QUERY, themeId], getThemeCustomizationRequest, {
    onError: (errors) => {
      console.log('Error ', errors);
    }
  });
};
