// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import Loader from '../../../../components/ui/loader';
import NoContent from '../../../../components/ui/no-content';
import Result from '../../../../components/ui/result';
import Table from './components/table';

// All V2 references must be removed after v2 is deprecated
type Props = {
  filteredDataV2: Array;
  filteredDataV3: Array;
  handleSelectedVendors: Function;
  selectedVendors: Array;
  GVLVersion: number
}

function VendorsTableGVL({ filteredDataV2, filteredDataV3, handleSelectedVendors, GVLVersion, selectedVendors }: Props) {
  const isGVLVersion3 = (GVLVersion === 3);
  const vendorsList = isGVLVersion3
    ? state => state.vendors.v3
    : state => state.vendors.v2;
  const vendorsData = useSelector(vendorsList);
  const { error, data, pending } = vendorsData;

  const isError = error && error.code !== 200 && error.message ? error.message : false;
  const filteredData = isGVLVersion3 ? filteredDataV3 : filteredDataV2;
  const isEmpty = (data && data.vendors && data.vendors.length === 0) && (filteredData && filteredData.length === 0);

  if (isError) {
    return <Result status="error" title={error.message} />;
  }

  if (pending) {
    return <Loader />;
  }

  if (isEmpty) {
    return (
      <NoContent
        description="List of vendors is empty"
      />
    );
  }

  const buildTableData = (baseData) => {
    let tableData = [];
    if (filteredData && filteredData.length > 0) {
      tableData = filteredData;
    } else if (Object.keys(baseData).length && baseData.vendors && baseData.vendors.length) {
      tableData = baseData.vendors;
    } else {
      return [];
    }

    return tableData.map(vendor => {
      const blockedByDefault = baseData.blockedByDefault;
      const whiteListedVendors = isGVLVersion3 ? baseData.whitelistedVendors : baseData.whiteListedVendors;
      
      if (blockedByDefault) {
         // assume all are blockedVendors except whitelistedVendors
         return {
          ...vendor,
          blocked: whiteListedVendors && whiteListedVendors.includes(vendor.id) ? false : true,
          children: [{
            id: `c-${vendor.id}`,
            policyUrl: isGVLVersion3 ? vendor.urls : vendor.policyUrl,
            purposes: baseData.purposes.filter(item => vendor.purposes.includes(item.id)),
            legIntPurposes: baseData.purposes.filter(item => vendor.legIntPurposes.includes(item.id)),
            flexiblePurposes: baseData.purposes.filter(item => vendor.flexiblePurposes.includes(item.id)),
            specialPurposes: baseData.specialPurposes.filter(item => vendor.specialPurposes.includes(item.id)),
            features: baseData.features.filter(item => vendor.features.includes(item.id)),
            specialFeatures: baseData.specialFeatures.filter(item => vendor.specialFeatures.includes(item.id))
          }]
        };
      } else {
        // assume all are whitelistedVendors except blockedVendors

        return {
          ...vendor,
          blocked: baseData.blockedVendors.includes(vendor.id),
          children: [{
            id: `c-${vendor.id}`,
            policyUrl: isGVLVersion3 ? vendor.urls : vendor.policyUrl,
            purposes: baseData.purposes.filter(item => vendor.purposes.includes(item.id)),
            legIntPurposes: baseData.purposes.filter(item => vendor.legIntPurposes.includes(item.id)),
            flexiblePurposes: baseData.purposes.filter(item => vendor.flexiblePurposes.includes(item.id)),
            specialPurposes: baseData.specialPurposes.filter(item => vendor.specialPurposes.includes(item.id)),
            features: baseData.features.filter(item => vendor.features.includes(item.id)),
            specialFeatures: baseData.specialFeatures.filter(item => vendor.specialFeatures.includes(item.id))
          }]
        };
      }
    });
  };

  return (
    <Table data={buildTableData(data)} handleSelectedVendors={handleSelectedVendors} isGVLVersion3={isGVLVersion3} selectedVendors={selectedVendors}/>
  );
}

export default VendorsTableGVL;
