import React, { useState, useEffect, useRef } from 'react';
import { Col, Form, Input, Select, Row, Card, Tabs, Modal, Button } from 'antd';
import { DISABLED_COP_LANGUAGES, getTranslationsForLang, SUPPORTED_LANGUAGES } from '../../../../utils/language';
import RichText from '../../../ui/forms/rich-text';
import Styles from '../themesForm.module.scss';
import { getDefaultCustomLinks, getDefaultLangs, getLanguageLabel, getPrivacyBodyForAdvancedConfig } from '../utils';
import CustomLinks from './custom-links';
import { useParams } from 'react-router-dom';
import { GLANCE_PCODE } from '../../../../utils/constants';
import TextAreaEditor from '../../../ui/forms/text-area-editor';

// @flow
interface IAdvancedConfigurationProps {
  validations: Object;
  setFieldsValue: Function;
  getFieldValue: Function;
  onValuesChange: Function;
  appPcode: string;
  idParams: string;
}

const fixedSegmentsByLang = {
  en: [
    'We and our [X] partners store and access information on your device (e.g., cookies, device identifiers) and process personal data (e.g., unique identifiers, browsing data) for personalized advertising, content measurement, audience insights, and other specified purposes.',
    'With your consent, we may utilize precise geolocation and device scanning technologies. You may review and manage the purposes for which your data is processed in the settings.',
    'Certain partners may process your data based on legitimate interest rather than consent. You retain the right to object to such processing, with further details available in the relevant section of our Privacy Settings.',
    'You may withdraw your consent or update your preferences at any time via Privacy Settings. Your choices apply exclusively to this site and will be retained for [X] months.',
  ],
  fr: [
    'Nous et nos [X] partenaires stockons et accédons aux informations sur votre appareil (par exemple, cookies, identifiants d’appareil) et traitons des données personnelles (par exemple, identifiants uniques, données de navigation) pour la publicité personnalisée, la mesure de contenu, les informations sur l’audience et d’autres finalités spécifiées.',
    'Avec votre consentement, nous pouvons utiliser des technologies de géolocalisation précise et de numérisation d’appareil. Vous pouvez examiner et gérer les finalités pour lesquelles vos données sont traitées dans les paramètres.',
    'Certains partenaires peuvent traiter vos données sur la base d’un intérêt légitime plutôt que du consentement. Vous conservez le droit de vous opposer à ce traitement, avec plus de détails disponibles dans la section correspondante de nos Paramètres de confidentialité.',
    'Vous pouvez retirer votre consentement ou mettre à jour vos préférences à tout moment via les Paramètres de confidentialité. Vos choix s’appliquent exclusivement à ce site et seront conservés pendant [X] mois.',
  ],
};

const AdvancedConfiguration = ({
  validations,
  setFieldsValue,
  getFieldValue,
  onValuesChange,
  appPcode,
  idParams,
  values
}: IAdvancedConfigurationProps) => {
  const { pathname } = window.location;
  const isNew = pathname.includes('/new');

  const isPublisherGlance = appPcode === GLANCE_PCODE;
  const currentSettings = getFieldValue('advancedCustomizationSettings');
  const initialLanguage = currentSettings && currentSettings.length > 0 ? currentSettings[0].language : 'en';
  const supportedLanguages = SUPPORTED_LANGUAGES.filter(lang => !DISABLED_COP_LANGUAGES.includes(lang.value));

  const [tabId, setTabId] = useState(initialLanguage);
  const [languages, setLanguages] = useState(getDefaultLangs(currentSettings));

  const [contents, setContents] = useState(() => ({
    en: `<${fixedSegmentsByLang.en[0]}> <${fixedSegmentsByLang.en[1]}> <${fixedSegmentsByLang.en[2]}> <${fixedSegmentsByLang.en[3]}>`,
    fr: `<${fixedSegmentsByLang.fr[0]}> <${fixedSegmentsByLang.fr[1]}> <${fixedSegmentsByLang.fr[2]}> <${fixedSegmentsByLang.fr[3]}>`,
  }));

  useEffect(() => {
    if (!isNew && values && values.advancedCustomizationSettings && values.advancedCustomizationSettings.length > 0) {
      const updatedContents = values.advancedCustomizationSettings.reduce((acc, item) => {
        const lang = item.language;
        const privacyBody = item.privacyBody || '';
        acc[lang] = privacyBody;
        return acc;
      }, {});

      setContents((prevContents) => ({
        ...prevContents,
        ...updatedContents,
      }));
    }
  }, [values]);

  const handleContentChange = (newContent) => {
    setContents((prevContents) => ({
      ...prevContents,
      [tabId]: newContent,
    }));
  };

  const privacyBodyChange = (value, lang) => {
    const currentValues = getFieldValue('advancedCustomizationSettings');

    const updatedValues = currentValues.map(item => {
      if (item.language === lang) {
        return { ...item, privacyBody: value };
      }
      return item;
    });
    console.log('updatedValues', updatedValues);
    setFieldsValue({ advancedCustomizationSettings: updatedValues });
    onValuesChange();
  };

  const tabItems = languages.map((lang, index) => {
    const translations = getTranslationsForLang(lang);

    return {
      key: lang,
      label: getLanguageLabel(lang),
      forceRender: true,
      children: (
        <Col span="24">
          <Form.Item noStyle name={['advancedCustomizationSettings', index, 'language']}></Form.Item>
          <Row gutter={16}>
            <Col span="12">
              <Form.Item
                label="Primary button text"
                name={['advancedCustomizationSettings', index, 'agreeButton']}
                rules={validations.textInput}
              >
                <Input disabled={isPublisherGlance ? false : true} placeholder={translations.agree || ''} />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name={['advancedCustomizationSettings', index, 'secondaryButton']}
                label="Secondary button text"
                rules={validations.textInput}
              >
                <Input placeholder={translations.saveAndExit} maxLength={isPublisherGlance ? 40 : 24} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form.Item
                label="Disagree button text/ “X” button tooltip"
                name={['advancedCustomizationSettings', index, 'disagreeButton']}
              >
                <Input
                  placeholder="Disagree"
                  placeholder={translations.disagree}
                  maxLength={isPublisherGlance ? 40 : 24}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <Form.Item label="Privacy Header" name={['advancedCustomizationSettings', index, 'privacyHeader']}>
                <Input placeholder={translations.mockPurposesCopies.headline || 'We value your privacy'} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="24">
              <TextAreaEditor
                tabId={tabId}
                lang={tabId} // Assuming lang aligns with tabId
                content={contents[tabId]}
                setContents={setContents}
                onChange={handleContentChange}
                fixedSegments={fixedSegmentsByLang[tabId] || fixedSegmentsByLang['en']}
                privacyBodyChange={privacyBodyChange}
                themeId={idParams}
              />
            </Col>
          </Row>
          <Form.Item noStyle name={['advancedCustomizationSettings', index, 'privacyBody']}></Form.Item>

          <CustomLinks
            lang={lang}
            index={index}
            validations={validations}
            getFieldValue={getFieldValue}
            setFieldsValue={setFieldsValue}
            fieldName={'advancedCustomizationSettings'}
          />
        </Col>
      ),
    };
  });

  const handleTabChange = (newTabId) => {
    setTabId(newTabId);
  };

  const handleLanguageChange = value => {
    const currentValues = getFieldValue('advancedCustomizationSettings');
    const added = value.find(item => !languages.includes(item));
    const removed = languages.find(item => !value.includes(item));
    if (added) {
      setFieldsValue({
        advancedCustomizationSettings: [
          ...currentValues,
          {
            language: added,
            privacyBody: getPrivacyBodyForAdvancedConfig(added, currentValues),
          },
        ],
      });
    }
    if (removed) {
      const updatedValues = currentValues.filter(item => item.language !== removed);
      setFieldsValue({ advancedCustomizationSettings: updatedValues });
      if (tabId === removed) {
        setTabId(value[0]);
      }
    }
    setLanguages(value);
  };
  
  return (
    <>
      <p style={{ marginBottom: '20px' }}> This will be applied for premium properties </p>
      <Card title="Choose Language" extra="GDPR">
        <Form.Item name="advancedConfigLanguages" label="Language" required>
          <Select
            defaultValue={languages}
            showSearch
            mode="multiple"
            onChange={handleLanguageChange}
            options={supportedLanguages}
            optionFilterProp="label"
            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
          />
        </Form.Item>
      </Card>
      <Card title="Customise Content" extra="GDPR">
        <Tabs
          size="small"
          type="card"
          onTabClick={handleTabChange}
          defaultActiveKey={tabId}
          items={tabItems}
          activeKey={tabId}
        />
      </Card>
    </>
  );
};

export default AdvancedConfiguration;
