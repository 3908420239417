import React from 'react';
import { Modal, Button } from 'antd';
import { TEXT } from '../../text';
import tickIcon from '../../../../assets/images/tick.svg';
import Styles from './cop.module.scss';

const SuccessCopModal = ({open, handleChange}) => {
    return (
        <div>
            <Modal
                title=''
                visible={open}
                closable={true}
                footer={null}
                // width={800}
                onCancel={handleChange}
            >
                <div className={Styles.success}>
                    <img src={tickIcon} alt="success" width={60} height={60} />
                    <p>{TEXT.MODAL.SUCCESS}</p>
                    <p>{TEXT.MODAL.CONTACT_US} <a href="https://support.inmobi.com/mytickets" target="_blank" rel="noopener noreferrer">Contact Us</a></p>
                    <div className={Styles.success_footer_btns}>
                        <Button type='primary' className={Styles.success_btn} onClick={handleChange}>Got it</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default SuccessCopModal;
