import { faCode, faDownload } from '@fortawesome/fontawesome-pro-light';
import { faEllipsisV, faFileAlt, faTrashAlt } from '@fortawesome/fontawesome-pro-solid';
import domPurify from 'dompurify';

import Badge from 'antd/es/badge';
import Button from 'antd/es/button';
// import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Popover from 'antd/es/popover';
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
// @flow
import React from 'react';
import Space from 'antd/es/space';
import Tooltip from 'antd/es/tooltip';
import uid from 'uid';
import Spin from '../components/ui/spin';
import type { iAnalytics } from '../interfaces/analytics';
import type { iSite, iSitesTableData } from '../interfaces/sites';
import { COUNTRY2_3 } from './constants';
import getAxiosInstance from '../data/axiosClient';

type buildTableDataProps = {
  actions: Array<any>;
  analytics?: Array<iAnalytics>;
  sites: Array<iSite>;
  themes: Array<iTheme>;
  audit?: Object;
  fetchPrepareDownload?: Function;
  fetchDownload?: Function;
  pcode: string;
  setSiteId: String;
  setShowDeleteDialog: Function;
}

export const ratePercentage = (value) => {
  if (!value) return 0;
  return value > 0 && value < 100 ? value.toFixed(2) : value.toFixed(0);
};

export const mapRegulationValue = (regulation) => {
  let regulationValue = '';
  if (regulation && typeof regulation === 'object') {
    const regulationJoin = regulation.join();
    switch (regulationJoin) {
      case 'GDPR':
        regulationValue = 'EU regulation (GDPR)';
        break;
      case 'USP':
        regulationValue = 'US regulation';
        break;
      case 'GDPR,USP':
        regulationValue = 'EU & US regulation';
        break;
      default:
        regulationValue = 'No Regulation';
        break;
    }
  } else {
    switch (regulation) {
      case 'GDPR':
        regulationValue = 'EU regulation (GDPR)';
        break;
      case 'US_PRIVACY':
        regulationValue = 'US regulation';
        break;
      case 'GDPR_AND_USP':
        regulationValue = 'EU & US regulation';
        break;
      default:
    }
  }

  return regulationValue;
};

export function buildActions(
  actions: buildTableDataProps.actions,
  site: any,
  audit: Object,
  siteHasAnalytics: Boolean,
  setOpenAuditForm: Function,
  setShowDeleteDialog: Function,
  setSiteId: string,
  propertyType: string
): Array<any> {
  const buildBtn = (action, siteObj) => {
    const btnContent = action.button.children;
    return (
      <Button
        {...action.button}
        type="text"
        onClick={() => action.button.onClick(siteObj)}
      >
        {btnContent}
      </Button>
    );
  };

  const buildLink = (action, siteObj) => (
    <Link
      to={`${action.link.to}/${siteObj.siteId}`}
      className="ant-btn ant-btn-text ant-btn-icon-only"
    >
      {action.link.children}
    </Link>
  );

  const deleteSite = (siteId) => {
    setShowDeleteDialog(true);
    setSiteId(siteId);
  };

  const buildPopOver = (siteObj, action) => {
    const generateAuditLogsElement = () => {
      const ButtonPrepareDownload = (
        <Button
          type="text"
          onClick={() => {
            if (siteHasAnalytics) {
              setOpenAuditForm({
                show: true,
                prepareDownloadLog: action.prepareDownloadLog,
                site: siteObj,
                siteHasAnalytics
              });
            } else {
              action.prepareDownloadLog({ ...siteObj, siteHasAnalytics });
            }
          }}
          icon={<FontAwesomeIcon icon={faFileAlt} />}
        >
          LOGS
        </Button>
      );
      const LoadingLogsSpinner = (
        <Button
          type="text"
          disabled
          loading
        >
          LOGS
        </Button>
      );
      let element = ButtonPrepareDownload;

      if (siteHasAnalytics && audit && audit.auditLogs.length) {
        const log = audit.auditLogs
          .find((auditLog) => {
            if (siteObj.url) {
              const auditLogUrl = new URL(auditLog.url.includes('http') ? auditLog.url : `https://${auditLog.url}`);
              const siteObjUrl = new URL(siteObj.url.includes('http') ? siteObj.url : `https://${siteObj.url}`);
              return auditLogUrl.hostname === siteObjUrl.hostname;
            }
            return siteObj.packageId === auditLog.url;
          });

        if (log) {
          const DownloadButton = (
            <Button
              type="text"
              onClick={
                () => action.download({
                  queryId: log.queryId,
                  logId: log.id,
                  domain: log.url,
                  auditLogs: audit.auditLogs.filter((
                    { queryState, isDownloaded }
                  ) => queryState === 'SUCCEEDED' && !isDownloaded),
                })
              }
              icon={<FontAwesomeIcon icon={faDownload} />}
            >
              LOGS
            </Button>
          );

          if (log.queryState === 'SUCCEEDED') {
            element = DownloadButton;
          } else if (log.queryState === 'RUNNING') {
            element = LoadingLogsSpinner;
          } else {
            element = ButtonPrepareDownload;
          }
        }
      }

      return element;
    };
    let menu = null;
    if (action.title === 'More Options') {
      menu = (
        <Space direction="vertical">
          {generateAuditLogsElement()}
          <Button
            type="text"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            onClick={() => deleteSite(siteObj.siteId)}
          >
            DELETE
          </Button>
          {siteObj.ampEnabled && (
          <Button
            type="text"
            onClick={() => action.getAmpTag(siteObj.url, siteObj.siteId)}
            icon={<FontAwesomeIcon icon={faCode} />}
          >
            GET AMP TAG
          </Button>
          )}
          {siteObj.applyToSubDomains && (
          <Button
            type="text"
            onClick={() => action.getTag(siteObj.url)}
            icon={<FontAwesomeIcon icon={faCode} />}
          >
            GET TAG
          </Button>
          )}
        </Space>
      );
    } else if (action.title === 'Reports') {
      menu = (
        <Space direction="vertical">
          <Button
            type="text"
            onClick={() => action.goToReports(propertyType, siteObj.siteId)}
          >
            Basic Analytics
          </Button>
          <Button
            type="text"
            onClick={() => action.goToPremiumReports(propertyType, siteObj.uuid)}
          >
            Advanced Analytics
          </Button>
        </Space>
      );
    }

    return (
      <Popover
        placement="bottom"
        content={menu}
        trigger="click"
      >
        <Button
          type="text"
          icon={<FontAwesomeIcon icon={action.icon} />}
        />
      </Popover>
    );
  };
  return actions.map((action) => {
    let children = null;
    if ('button' in action) children = buildBtn(action, site);
    if ('link' in action) children = buildLink(action, site);
    if ('popover' in action) children = buildPopOver(site, action);
    return (
      <Tooltip
        title={action.title}
        key={uid()}
      >
        {children}
      </Tooltip>
    );
  });
}

const getDetailsPageLink = (pcode, property) => {
  const route = property.propertyType === 'SITE' ? 'sites' : 'apps';
  const label = property.propertyType === 'SITE' ? property.url : property.packageId;

  return (
    <Link
      data-test={`${property.propertyType.toLowerCase()}_${property.siteId}_view_detail`}
      to={`/protect/p-${pcode}/${route}/detail/${property.siteId}`}
    >
      {label}
    </Link>
  );
};

export function buildTableData(
  data: buildTableDataProps,
  setOpenAuditForm: Function
): Array<iSitesTableData> {
  const { analytics, sites, themes, actions, audit, cmpVersionV2, pcode, setSiteId, setShowDeleteDialog } = data;

  return sites.map((site) => {
    const ROOT = site.propertyType === 'SITE' ? 'sites' : 'apps';

    if (site.cmpVersion === 'latest' && cmpVersionV2) {
      site.cmpVersion = cmpVersionV2.list[0];
    }
    if (true) {
      actions[0].popover = 'popover';
    } else {
      actions[0].link.to = `/protect/p-${pcode}/${ROOT}/detail`;
    }
    actions[1].link.to = `/protect/p-${pcode}/${ROOT}/edit`;

    const siteAnalytics = analytics && analytics.list.length ? analytics.list.filter((item) => {
      const propertyIdentifier = ROOT === 'sites' ? site.siteId : site.siteId;
      return item['1'] && item['1'].length > 0 && item['1'][0].propertyId === propertyIdentifier;
    }) : [];

    let consentRate = <b>0%</b>;
    let status = 'warning';

    if (siteAnalytics.length) {
      const gdprData = siteAnalytics[0]['1'];
      if (gdprData[0].totalConsentCookies) {
        status = 'success';
      }
      consentRate = <b>{`${ratePercentage(gdprData[0].cookiesConsentRatePercentage)}%`}</b>;
    } else if (analytics && analytics.pending) {
      consentRate = <Spin size="small" />;
    }

    site = {
      ...themes.filter(item => item.themeId === site.themeId)[0],
      ...site,
      id: site.siteId
    };

    const siteRowData = {
      ...site,
      url: getDetailsPageLink(pcode, site),
      status: React.cloneElement(<Badge />, { status, size: 'small' }),
      regulation: mapRegulationValue(site.privacyMode || site.privacyModes),
      consentRate,
      propertyTypeLabel: site.propertyType === 'SITE' ? 'WebSite' : 'App',
      actions: (
        <Space>
          {buildActions(
            actions,
            site,
            audit,
            (!!siteAnalytics.length && ratePercentage(siteAnalytics[0]['1'][0].consentRatePercentage) > 0),
            setOpenAuditForm,
            setShowDeleteDialog,
            setSiteId,
            ROOT
          )}
        </Space>
      )
    };

    return siteRowData;
  });
}

export function formatDataToSubmit(data: any): Object {
  const formData = { ...data };

  formData.purposeIds = formData.purposeIdsV2;
  formData.purposeLegitimateIds = formData.purposeLegitimateIdsV2;
  delete formData.purposeIdsV2;
  delete formData.purposeLegitimateIdsV2;

  Object.keys(formData).forEach((key) => {
    if (Array.isArray(formData[key]) && formData[key] && !(formData[key].length)) {
      delete formData[key];
    }

    if (key === 'consentScope' && formData[key] === 'service') {
      delete formData.consentScopeGroupURL;
      delete formData.cookieAccessUrl;
    }

    return false;
  });

  return formData;
}

export const validateUserInput = formData => Object.values(formData).every((value) => {
  if (!value) return true;
  if (Array.isArray(value) && value.length === 0) return true;

  const sanitisedValue = domPurify.sanitize(value);
  return sanitisedValue === value;
});

export const isFieldEnabled = (fieldName, version) => {
  if (!fieldName || !version) return false;
  if (version === 'latest') return true;
  switch (fieldName) {
    case 'urlPassThrough':
    case 'adsDataRedaction':
    case 'legitimateInterestOptIn':
      return version >= '54';
    case 'gdprPrivacyLink':
      return version >= '55';
    case 'includeVendorsDisclosedSegment':
      return version >= '56';
    case 'cop': 
      return version >= '59';
    case 'gdprEnabledInUS': 
      return version >= '60';
    default:
      return false;
  }
};


export function country3(country2) {
  return (COUNTRY2_3[country2.toUpperCase()] || 'XXX');
}

export const COP_ENABLED_PCODES = [
  'aNG_Sbx7HrzaG', 
  'JYWDqeLS64fbt', 
  '5N7YqDr9aw-kv', 
  'j2dMRQDxNvEY6', 
  't2tXPeNxL5ywu', 
  '8F9Dnv0Zkrjf5', 
  'LR0urNA227kmT',
  'cs41rqMUk0h46',
  '-1xd15A5t81dj',
  'PRrmquD1Ggcb1',
  'EPBdh-ebfy6VE',
  'YLXGxf7w_CXtt',
  'xBMpAuESvQJgy',
  'PzXWA9q2wjDf-',
  'RF8ZdWZScN9RR',
  'eYc-nzCX4qY_x',
  'YJ5b1xZKF-79A',
  '3Tk54nhQnu-Lr',
  'F_sXZSkKV3zjU',
  '14bbPApgcwHL-',
  'n1tAAA6JQDb3b',
  'GGCdjccSp1qc-',
  'cJsduNRegvC-s',
  '30qZrn5aJ5pCe',
  'A4DJWvuGZbU4b'
];

const requestCustomization = getAxiosInstance(process.env.REACT_APP_CHOICE_2_V2_CUSTOMIZATION_API_URL);
const url = '/site';

export const enableCOPForPCode = (pCode) => {
  return COP_ENABLED_PCODES.includes(pCode);
};