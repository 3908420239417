import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tabs, Space, Button, Dropdown } from 'antd/es';
import { TabsProps } from 'antd/es/tabs';
import { useParams } from 'react-router-dom';
import withPcode from '../../components/hoc/withPcode';
import withNavbar from '../../components/hoc/with-navbar';
import Styles from './premiumReports.module.scss';
import Select from '../../components/ui/select';
import Dashboard from './dashboard/dashboard';
import { usePremiumSitesList } from '../../data/hooks/sites';
import Analytics from './analytics';
import ViewReports from './analytics/viewReports';
import ErrorBoundary from './errorBoundary';
import noDataIcon from '../../assets/images/analytics/noData.svg';
import TEXT from './text';
import { MoreOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetJobData } from '../../data/hooks/analytics';

// @flow
interface IPremiumReportsProps {
  app: {
    accountId: number,
    pCode: string
  };
}

const getPremiumSitesOptions = (sites) => {
  if (sites.properties.length === 0) return [];

  const siteOptions = sites.properties.map(site => ({
    value: site.siteUuid,
    label: site.url,
    disabled: site.isDisabled
  }));
  const allOption = { value: 'all', label: 'All', disabled: false };
  return [allOption, ...siteOptions];
};

const { Option } = Select;

const PremiumReports = ({ app }: IPremiumReportsProps) => {
  const { id } = useParams();
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search);
  const jobId = urlParams.get("report_id");

  const [tabId, setTabId] = useState('dashboard');
  const [propertyId, setPropertyId] = useState([]);

  const { isLoading: isSitesLoading, data: premiumSites } = usePremiumSitesList(app.pCode);
  const premiumSitesOptions = premiumSites ? getPremiumSitesOptions(premiumSites) : [];
  const allPremiumSiteIds = premiumSites && premiumSites.properties
    ? premiumSites.properties.map(site => site.siteUuid) : [];

  const { isLoading: isJobLoading, data: jobData, refetch: refetchGetJobData, isSuccess } = useGetJobData(app.pCode, jobId, !jobId);

  useEffect(() => {
    const allPremiumSiteIds = premiumSites && premiumSites.properties
    ? premiumSites.properties.map(site => site.siteUuid) : [];

    setPropertyId(allPremiumSiteIds);
  }, [premiumSites]);

  useEffect(() => {
    if (id) setPropertyId([id]);
  }, [id]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (tabId === 'analytics' && urlParams.get('report_id')) {
      setTabId('analytics');
    }
  }, [tabId]);

  useEffect(() => {
    if (jobId) {
      refetchGetJobData();
    }
  }, [location.search]);

  useEffect(() => {
    if (isSuccess && jobData && jobData.data) {
      const propertyIds = jobData.data.query.filters.find(el => el.key === 'propertyId');
      if (propertyIds && propertyIds.values) {
        setPropertyId(propertyIds.values);
        setTabId('analytics');
      }
    }  
  }, [isSuccess]);

  const handleTabChange = (key) => {
    setTabId(key);
  };

  const tabItems: TabsProps = [
    {
      key: 'dashboard',
      label: 'Dashboard',
      children: (
        <ErrorBoundary>
          <Dashboard propertyId={propertyId} pCode={app.pCode} />
        </ErrorBoundary>
      )
    },
    {
      key: 'analytics',
      label: 'Analytics',
      children: (
        <ErrorBoundary>
          <Analytics propertyId={propertyId} pCode={app.pCode} setTabId={setTabId}  jobData={jobData}/>
        </ErrorBoundary>
      ),
    }
  ];

  const handlePropertyChange = (values) => {
    if (values.includes('all')) {
      setPropertyId(allPremiumSiteIds);
    } else {
      setPropertyId(values);
    }
  };

  return (
    <>
      <section className={Styles.wrapper}>
        <section className={Styles.container}>
          <div className={Styles.propertySelection}>
            <Select
              required
              placeholder="Select properties"
              className={Styles.dropDown}
              loading={isSitesLoading}
              mode="multiple"
              onChange={handlePropertyChange}
              allowClear
              maxTagCount="responsive"
              value={propertyId}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().includes(input.toLowerCase()) ||
                option.props.value.toLowerCase().includes(input.toLowerCase())
              }
            >
              {premiumSitesOptions &&
                premiumSitesOptions.map(opt => (
                  <Option key={opt.value} value={opt.value} disabled={opt.disabled}>
                    {opt.label}
                  </Option>
                ))}
            </Select>

            <Button type="link" onClick={() => history.push(`/protect/p-${app.pCode}/reports/view-reports`)}>
              View All Reports
            </Button>
          </div>
          <main className={Styles.content}>
            {propertyId.length !== 0 ? (
              <Tabs
                size="small"
                onTabClick={handleTabChange}
                defaultActiveKey={tabId}
                className={Styles.tabs}
                items={tabItems}
                activeKey={tabId}
              />
            ) : (
              <div className={Styles.noData}>
                <Space direction="vertical">
                  <div className={Styles.icon}>
                    <img src={noDataIcon} alt="No Data" />
                  </div>
                  <p>{TEXT.NO_DATA_LINE1}</p>
                  <p>{TEXT.NO_DATA_LINE2}</p>
                </Space>
              </div>
            )}
          </main>
        </section>
      </section>
    </>
  );
};

const MapStateToProps = ({ app, themes }) => ({ app, themes });
export default connect(MapStateToProps)(withPcode(withNavbar(PremiumReports)));
