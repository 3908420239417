// @flow
import React from "react";
import Styles from './Announcement.module.scss';
import { Tag } from 'antd';
import speaker from '../../../../assets/images/speaker.svg';
import { CloseOutlined } from "@ant-design/icons";

interface iAnnoucementProps {
    message: string;
    action: Function;
    actionText?: string;
    closable?: boolean;
    closeAction?: Function;
    requestSubmitted?: boolean;
}

const Announcement = ({ message, action, actionText, closable, closeAction, requestSubmitted }: iAnnoucementProps) => {
    return (
        <div className={Styles.announcement_wrapper}>
            <div className={Styles.top} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={speaker} alt="speaker" style={{ marginRight: '8px' }} />
                    <Tag color="#E0EAFD" style={{ color: '#2F72F4', marginRight: '8px' }}>NEW</Tag>
                </div>
                {
                    closable && <CloseOutlined style={{ color: "#bdbdbd", cursor: 'pointer' }} onClick={closeAction}/>
                }
            </div>
            <p className={Styles.message}>{message}</p>
            <button className={requestSubmitted ? Styles.success_link : Styles.link} onClick={action} >{actionText}</button>
        </div>
    );
};

export default Announcement;