import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { TEXT } from '../../text';
import cogIcon from '../../../../assets/images/package-customization.svg';
import copImage from '../../../../assets/images/cop_image.svg';
import Styles from './cop.module.scss';

const CopAnnouncementModal = ({open, handleChange, handleRequestFeatureAccess}) => {
    return (
        <div>
            <Modal
                title='🚀 Introducing "Consent or Pay" in CMP!'
                visible={open}
                closable={true}
                footer={null}
                width={800}
                onCancel={handleChange}
            >
                <p>{TEXT.MODAL.COP_SUBHEADING}</p>

                <div className='flex' style={{
                    gap: '20px'  
                }}>
                    <img src={copImage} alt="cop" width={500} height={400} style={{
                        objectFit: 'cover',
                    }}/>

                    <div className='flex flex-column' style={{
                        marginTop: '50px',
                        alignItems: 'center',
                    }}>
                        <img src={cogIcon} alt="cog" width={80} height={80} />

                        <ul className={Styles.ul}>
                            <li className={Styles.link}>
                                Customize <strong>messages, languages, and button text.</strong>
                            </li>

                            <li className={Styles.link}>
                                Enable option to <strong>make payment or promote subscription.</strong>
                            </li>

                            <li className={Styles.link}>
                                <strong>Seamlessly integrate</strong> with your CMP.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={Styles.footer_btns}>
                    <Button type='default' className={Styles.button}>Learn More</Button>

                    <Button type='primary' className={Styles.button} onClick={handleRequestFeatureAccess}>Request Feature Access</Button>
                </div>
            </Modal>
        </div>
    );
};

export default CopAnnouncementModal;
