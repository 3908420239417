/* eslint-disable import/prefer-default-export */
import { requestCustomization, requestCustomizationV2 } from '../../utils/requests';

const url = '/theme';

export function getAll([pCode, type]) {
  return requestCustomizationV2
    .get(url, { params: { pCode, themeType: type, sort: 'themeName,ASC' } })
    .then(res => ({ res: res.data }))
    .catch(err => ({ err }));
}

export function createTheme([data]) {
  return requestCustomizationV2
    .post(url, data)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function createTextCustomizationRequest([data]) {
  return requestCustomization
    .post('/text-customization-request', data)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function getById([id]) {
  return requestCustomizationV2
    .get(`${url}/${id}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function editTheme([id, data]) {
  return requestCustomizationV2
    .put(`${url}/${id}`, data)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}

export function deleteTheme([id]) {
  return requestCustomizationV2
    .delete(`${url}/${id}`)
    .then(res => ({ res }))
    .catch(err => ({ err }));
}
