import { Tag } from 'antd';
import React from 'react';

function getMenuElements() {
  return [
    { path: 'properties', label: 'Properties' },
    { path: 'themes', label: 'Themes' },
    { path: 'vendors', label: 'Vendors' },
    {
      path: 'reports',
      label: (
        <>
          Reports{' '}
          <Tag
            color="#E0EAFD"
            style={{
              color: '#2F72F4',
            }}
          >
            NEW
          </Tag>
        </>
      ),
    },
  ];
}

export default getMenuElements();
