import { ALERTS, BUILD, SITES, THEMES } from '../actions';
/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects';
import { createTextCustomizationRequest, createTheme, deleteTheme, editTheme, getAll, getById } from '../requests/themes';

import { CURRENT_MAJOR_VERSION } from '../../utils/constants';
import { buildError } from '../../utils/reducer';
import { formatDataToSubmit } from '../../utils/themes';

export function* fetchAllThemes({ payload }) {
  yield put({ type: THEMES.GET_ALL.PENDING });

  const { res, err } = yield call(getAll, [payload.pcode, payload.type]);

  if (res) {
    yield put({
      type: THEMES.GET_ALL.FULFILLED,
      payload: res.filter(theme => theme.majorVersion === CURRENT_MAJOR_VERSION)
    });
  } else {
    yield put({ type: THEMES.GET_ALL.REJECTED, payload: err.response });
  }
}

export function* fetchCreateTheme({ payload }) {
  yield put({ type: THEMES.CREATE.PENDING });

  const formData = formatDataToSubmit(payload.themeData);
  const { res, err } = yield call(createTheme, [formData]);

  const textCustomizationData = localStorage.getItem('text_customization_request_data');

  if (textCustomizationData) {
    const data = JSON.parse(textCustomizationData);
    const { customizationText, customizationType, language, reason } = data;
    const themeId = res.data.themeId;

    const { res: resTextCustomization, err: errTextCustomization } = yield call(createTextCustomizationRequest, [{
      themeId,
      customizationText,
      customizationType,
      language,
      reason,
    }]);

    localStorage.removeItem('text_customization_request_data');
  }

  if (res) {
    yield put({ type: THEMES.CREATE.FULFILLED, payload: res.data });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'success',
        message: `The theme ${res.data.themeName} was created successfully`,
        closable: true,
        autoClose: true
      }
    });
    if (payload.fromSite) {
      const { fromSite } = payload;
      yield put({
        type: SITES.EDIT.FETCH,
        payload: { siteId: fromSite, siteData: { themeId: res.data.themeId } }
      });
      yield put({
        type: BUILD.THEME.FETCH,
        payload: { themeId: res.data.themeId, pCode: res.data.pCode }
      });
    }
  } else {
    const message = err.response.data && err.response.data.message
      ? err.response.data.message : 'Error creating theme';
    yield put({
      type: THEMES.CREATE.REJECTED,
      payload: {
        status: err.response.data.status,
        message
      }
    });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'error',
        message: `Fail to create theme: ${message}`,
      }
    });
  }
}

export function* fetchEditTheme({ payload }) {
  yield put({ type: THEMES.EDIT.PENDING });

  const formData = formatDataToSubmit(payload.themeData);
  const { res, err } = yield call(editTheme, [payload.themeId, formData]);

  if (res) {
    yield put({
      type: BUILD.THEME.FETCH,
      payload: { themeId: res.data.themeId, pCode: res.data.pCode }
    });
    yield put({
      type: THEMES.EDIT.FULFILLED,
      payload: {
        data: res.data,
        id: parseInt(payload.themeId, 10)
      }
    });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'success',
        message: `The theme ${res.data.themeName} was edited successfully`,
        closable: true,
        autoClose: true
      }
    });
  } else {
    const message = err.response.data && err.response.data.message
      ? err.response.data.message.toLowerCase() : 'Error editing theme';
    yield put({
      type: THEMES.EDIT.REJECTED,
      payload: {
        status: err.response.data.status,
        message
      }
    });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'error',
        message: `Fail to edit theme: ${message}`
      }
    });
  }
}

export function* fetchThemeById({ payload }) {
  yield put({ type: THEMES.GET_BY_ID.PENDING });
  const { res, err } = yield call(getById, [payload.id]);

  if (res) {
    if (
      res.data.pCode === 'inmobi'
      || res.data.pCode === payload.pCode
    ) {
      yield put({ type: THEMES.GET_BY_ID.FULFILLED, payload: res.data });
    } else {
      yield put({ type: THEMES.GET_BY_ID.REJECTED, payload: buildError({ status: 401, message: 'Unauthorized' }) });
    }
  } else {
    yield put({ type: THEMES.GET_BY_ID.REJECTED, payload: err.response });
  }
}

export function* fetchDeleteTheme({ payload }) {
  yield put({ type: THEMES.DELETE.PENDING });
  const { res, err } = yield call(deleteTheme, [payload.idTheme]);

  if (res) {
    yield put({ type: THEMES.DELETE.FULFILLED, payload: { id: payload.idTheme } });
    yield put({
      type: THEMES.GET_ALL.FETCH,
      payload: { pcode: payload.pCode, isPremium: true }
    });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'success',
        message: `The theme ${payload.nameTheme} was deleted successfully`,
        closable: true,
        autoClose: true
      }
    });
  } else {
    const message = err.response.data && err.response.data.message
      ? err.response.data.message.toLowerCase() : 'Error deleting the theme';
    yield put({ type: THEMES.DELETE.REJECTED, payload: err });
    yield put({
      type: ALERTS.ADD,
      payload: {
        type: 'error',
        message: `Fail to delete ${payload.nameTheme}: ${message}`,
        closable: true,
        autoClose: true
      }
    });
  }
}
